<template>
  <div class="exhibitionManageBox">
    <vab-query-form class="ml-10" style="padding-top: 20px">
      <vab-query-form-left-panel :span="24">
        <el-form :inline="true" :model="state.queryForm" @submit.prevent>
          <el-form-item label="应用名称">
            <el-input v-model="state.queryForm.meetingName" class="mr-10" placeholder="请输入应用名称" clearable />
          </el-form-item>

          <el-form-item label="应用状态">
            <el-select v-model="state.queryForm.isShelf" class="m-2" placeholder="请选择应用状态" @change="handleQueryData">
              <el-option v-for="item in state.typeOptions" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>

          <el-form-item label="应用类型">
            <el-select v-model="state.queryForm.aiApplicationType" @change="handleQueryData" clearable>
              <el-option v-for="(item, index) in state.appTypeList" :value="item.type" :label="item.name" :key="item.type"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button icon="Search" type="primary" @click="handleQueryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-left-panel>
    </vab-query-form>

    <el-row :gutter="10">
      <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="6" v-for="(item, index) in state.list" :key="index">
        <div class="box-card">
          <div class="title">
            <div class="name">{{ item.meetingName }}</div>
            <el-icon class="editIcon" v-if="hasPermission(['exhibition:edit'])" @click="handleEdit(item)">
              <Edit />
            </el-icon>
          </div>
          <div class="form">
            <div class="form-item">
              <div class="label">应用状态</div>
              <div class="item">
                <div class="yishangjia iconbox" v-if="item.isShelf == true">
                  已上架
                </div>
                <div class="yixiajia iconbox" v-else>已下架</div>
              </div>
            </div>
            <div class="form-item">
              <div class="label">应用类型</div>
              <div class="item">
                {{ getAppType(item.aiApplicationType) }}
              </div>
            </div>
            <div class="form-item">
              <div class="label">应用时间</div>
              <div class="item">
                {{ item.listTime ? item.listTime : '暂无' }}
              </div>
            </div>
            <div class="form-item">
              <div class="label">创建人姓名</div>
              <div class="item">
                {{ item.hostName ? item.hostName : '暂无' }}
              </div>
            </div>
            <div class="form-item">
              <div class="label">创建人手机号</div>
              <div class="item">
                {{ item.hostPhone ? item.hostPhone : '暂无' }}
              </div>
            </div>
            <div class="form-item">
              <div class="label">应用Code</div>
              <div class="item">
                {{ item.code }}
              </div>
            </div>
            <div class="form-item">
              <div class="label">备注</div>
              <div class="item">
                {{ item.exRemark }}
              </div>
            </div>
          </div>
          <div class="controllBox">
            <div class="dropdown">
              <div class="btn1">数据中心</div>
              <div class="dropdown-content">
                <div class="dropdown-menu">
                  <div class="menuItem">
                    <div class="btn" @click="handleStatisticsDetail(item)" v-if="
                        hasPermission([
                          'user:admin:event:report:meeting:statistics',
                        ])
                      ">
                      数据统计
                    </div>
                  </div>
                  <div class="menuItem" v-if="item.aiApplicationType == 'EXHIBITION'">
                    <div class="btn" @click="handleDaliyDetail(item)" v-if="
                        hasPermission([
                          'user:admin:event:report:meeting:dailyDetail',
                        ])
                      ">
                      用户日报
                    </div>
                  </div>
                  <div class="menuItem">
                    <div class="btn" @click="downLoadReport(item, 'media')" v-if="
                        hasPermission(['user:exhibition:exportRegisterReport'])
                      ">
                      注册报表
                    </div>
                  </div>
                  <div class="menuItem" v-if="item.aiApplicationType == 'EXHIBITION'">
                    <div class="btn" @click="downLoadReport(item, 'signUp')" v-if="
                        hasPermission(['user:exhibition:exportExSignUpReport'])
                      ">
                      签到数据
                    </div>
                  </div>
                  <div class="menuItem">
                    <div class="btn" @click="downLoadReport(item, 'order')" v-loading.fullscreen.lock="fullscreenLoading" v-if="
                        hasPermission(['user:exhibition:exportExOrderReport']) && item.aiApplicationType == 'EXHIBITION'
                      ">
                      {{
                        item.aiApplicationType != 'EXHIBITION'
                          ? '应用订单报表'
                          : '展会订单报表'
                      }}
                    </div>
                  </div>
                  <div class="menuItem">
                    <div class="btn" @click="downLoadReport(item, 'business')" v-loading.fullscreen.lock="fullscreenLoading" v-if="
                        hasPermission(['user:exhibition:exportExOrderReport'])
                      ">
                      商机配对报表
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="dropdown">
              <div class="btn1">
                {{
                  item.aiApplicationType != 'EXHIBITION'
                    ? '应用管理'
                    : '展会管理'
                }}
              </div>
              <div class="dropdown-content">
                <div class="dropdown-menu">
                  <div class="menuItem">
                    <div class="btn" @click="handlePreview(item)" v-if="hasPermission(['user:qrcode:getQrCodeUrls'])">
                      预览
                    </div>
                  </div>
                  <div class="menuItem">
                    <div class="btn" @click="handlePageConfig(item)" v-if="hasPermission(['user:exhibition:forum'])">
                      页面模版
                    </div>
                  </div>
                  <div class="menuItem">
                    <div class="btn" @click="handleManageRegister(item)" v-if="hasPermission(['user:admin:attend:auditList'])">
                      注册管理
                    </div>
                  </div>
                  <div class="menuItem">
                    <div class="btn" @click="handleBrand(item)" v-if="hasPermission(['user:exhibitor:query'])">
                      机构管理
                    </div>
                  </div>
                  <div class="menuItem" v-if="item.aiApplicationType == 'EXHIBITION'">
                    <div class="btn" @click="handleGuest(item)" v-if="hasPermission(['user:qrcode:getQrCodeUrls'])">
                      嘉宾管理
                    </div>
                  </div>

                  <!-- <div class="menuItem">
                    <div
                      class="btn"
                      @click="handleActiveAssociation(item)"
                      v-if="hasPermission(['cms:activityInfo:page'])"
                    >
                      活动关联
                    </div>
                  </div> -->
                  <div class="menuItem">
                    <div class="btn" @click="handleForume(item)" v-if="hasPermission(['user:exhibition:forum'])">
                      论坛关联
                    </div>
                  </div>

                  <div class="menuItem">
                    <div class="btn" @click="handleMatching(item)" v-if="hasPermission(['user:exhibition:forum'])">
                      供需匹配管理
                    </div>

                  </div>

                  <div class="menuItem" v-if="hasPermission(['TransferTeamwork'])">
                    <div class="btn" @click="handleUserManageFunc(item)">
                      协同管理
                    </div>
                  </div>


                  <div class="menuItem">
                    <div class="btn" @click="handleLotteryManage(item)">
                      奖品/积分管理
                    </div>
                  </div>



                </div>
              </div>
            </div>
            <div class="dropdown" v-if="
                item.aiApplicationType == 'EXHIBITION' &&
                state.tenantKey != 'J80A84ZBBA40PW1'
              ">
              <div class="btn1">门票管理</div>
              <div class="dropdown-content">
                <div class="dropdown-menu">
                  <div class="menuItem">
                    <div class="btn" @click="handleJumpTicketsConfig(item)" v-if="hasPermission(['user:ticket:getTicketCategory'])">
                      门票配置
                    </div>
                  </div>
                  <div class="menuItem">
                    <div class="btn" @click="handleJumpTicketsManage(item)" v-if="hasPermission(['user:ticket:select'])">
                      门票管理
                    </div>
                  </div>
                  <div class="menuItem">
                    <div class="btn" @click="handleChannelCode(item)" v-if="hasPermission(['user:channel:page'])">
                      购票渠道
                    </div>
                  </div>

                  <!-- <div class="menuItem">
                    <div class="btn" @click="handleBrandProduct(item)" v-if="hasPermission(['user:exhibit:query'])">
                      展品列表管理
                    </div>
                  </div> -->
                  <!-- <div class="menuItem">
                    <div class="btn" @click="handleSonAccount(item)" v-if="
                        hasPermission([
                          'user:admin:exhibit:account:addSynergyAccount',
                        ])
                      ">
                      添加协同账号
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
            <div class="dropdown">
              <div class="btn1">渠道管理</div>
              <div class="dropdown-content">
                <div class="dropdown-menu">
                  <div class="menuItem">
                    <div class="btn" @click="handleChannel(item)" v-if="hasPermission(['user:register:channel:list'])">
                      主办渠道
                    </div>
                  </div>
                  <div class="menuItem">
                    <div class="btn" @click="handleShareChannel(item)" v-if="hasPermission(['user:register:channel:list'])">
                      分享渠道
                    </div>
                  </div>
                  <div class="menuItem">
                    <div class="btn" @click="handleshareCodeChannel(item)" v-if="hasPermission(['user:register:channel:list'])">
                      邀请码渠道
                    </div>
                  </div>
                  <div class="menuItem">
                    <div class="btn" @click="handleChanneluser(item)" v-if="hasPermission(['user:register:channel:list'])">
                      非报名渠道
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-pagination v-if="state.total > 9" background :current-page="state.queryForm.pageNum" :layout="state.layout" :page-size="state.queryForm.pageSize" :total="state.total" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
    <!-- 编辑 -->
    <edit-com ref="refExDialog" @noticeRefresh="getData" />
    <!-- 预览 -->
    <preview-com ref="refExPreview" :url="state.qrcodeUrl" />
    <generateWxVue ref="generateWxRef"></generateWxVue>

    <export-register ref="exportRegisterRef"></export-register>
    <holdMeetUserManage ref="holdMeetUserManageRef" :meeting-type="'EXHIBITION'"></holdMeetUserManage>
  </div>
</template>
<script>
import generateWxVue from '@/components/generateWx/generateWx.vue'
import exportRegister from './components/exportRegister.vue'
import holdMeetUserManage from './components/holdMeetUserManage.vue'
export default {
  name: 'ExhibitionManage',
  components: { generateWxVue, exportRegister, holdMeetUserManage },
}
</script>
<script setup>
//     USED("USED", "已使用"),
//     WAIT_USED("WAIT_USED", "待使用"),
//     EXPIRED("EXPIRED", "已过期"),
//     CLOSED("CLOSED", "已关闭"),
//     NOT_START("NOT_START", "未开始"),
//     CAN_USED("CAN_USED", "可使用"),
//     展会活动 EXHIBITION
//     社群协会 SOCIAL_COMMUNITY
//     城市荟客厅 CITY_PARLOR
//     机构荟客厅 ORG_PARLOR
//     其他 other
import { onActivated, reactive, ref, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'
import { useComponent } from './components/index'
import { Edit } from '@element-plus/icons'
import {
  exShelfAndUnshelfList,
  exportExOrderReport,
  exportMediaRegisterReport,
  exportExTicketReport,
  exportExSignUpReport,
  pushDataToGate,
  getQrcodeUrls,
  exportMeetingInvitedReport,
  exhibitionQueryPermissions,
  appConfigGetlist,
} from '@/api/exhibitionManage'
import { debounce } from '@/utils/debounce'
import { ElMessageBox, ElMessage } from 'element-plus'
import { getUserEnterPriseAccount } from '@/api/user'

import { parseTime } from '@/utils/index'
import { hasPermission } from '@/utils/permission'
// 引入组件
const { editCom, previewCom } = useComponent()
const filterExStatus = {
  true: '已上架',
  false: '已下架',
  null: '暂无',
}
const router = useRouter()
const refExDialog = ref(null) // 编辑弹框
const refExPreview = ref(null) // 预览弹框
const generateWxRef = ref(null) // 预览弹框
const exportRegisterRef = ref(null)
const loading = ref(false) // 列表动画加载
const associationRef = ref(null) // 列表动画加载
const holdMeetUserManageRef = ref(null)

const { proxy } = getCurrentInstance()

const filterTime = (val) => {
  return val ? parseTime(val) : '暂无'
}

const state = reactive({
  queryForm: {
    isShelf: '',
    meetingName: '',
    pageNum: 1,
    pageSize: 12,
    status: '',
    aiApplicationType: '',
  },
  tenantKey: '',
  qrCodeParams: {},
  permissionTarget: [''],
  enterPriseList: [],
  size: 'large',
  collapseItem: 0,
  controllBtnWidth: '100%',
  total: 0,
  layout: 'prev, pager, next',
  list: [],
  qrcodeUrl: '', // 二维码
  typeOptions: [
    {
      label: '全部',
      value: '',
    },
    {
      label: '上架中',
      value: 'true',
    },
    {
      label: '已下架',
      value: 'false',
    },
  ],
  appTypeList: [
    {
      label: '全部',
      value: '',
    },
  ],
})

const getAppType = (type) => {
  let name = ''
  name = state.appTypeList.filter((v) => v.type === type)[0].name
  return name
}

const getData = async () => {
  loading.value = true
  const { data } = await exShelfAndUnshelfList(state.queryForm)
  const { data: typeList } = await appConfigGetlist()
  state.list = data.data
  state.total = data.total || 0
  setTimeout(() => {
    loading.value = false
  }, 500)
  let arr = [
    {
      name: '全部',
      type: '',
    },
  ]
  arr = [...arr, ...typeList]
  state.appTypeList = arr
}

const handlePushGate = (row) => {
  ElMessageBox.confirm('是否确认推送数据至租达人闸机', '提示', {
    distinguishCancelAndClose: true,
    confirmButtonText: '确认',
    cancelButtonText: '取消',
  }).then(() => {
    pushDataToGate({ value: row.code })
      .then((res) => {
        ElMessage({
          message: '推送成功',
          type: 'success',
        })
      })
      .catch((err) => {
        ElMessage.error('推送失败')
      })
  })
}
// 下拉事件
const handleChangeSelect = (e) => {
  state.queryForm.isShelf = e
}
const handleCloseDialog = () => {
  refExDialog.value.handleClose()
}
// 查询
const handleQueryData = debounce(() => {
  state.queryForm.pageNum = 1
  state.collapseItem = 0
  getData()
})
// 预览
const handlePreview = (row) => {
  // getQrcodeUrls(row.code, 'HOLD_EXHIBITION').then((res) => {
  //   state.qrcodeUrl = res.data[0].qrcodeUrl
  //   refExPreview.value.handleOpen()
  // })
  console.log(row)
  let qrCodeParams = {
    autoColor: false,
    b: 0,
    env: '',
    g: 0,
    jumpPath: row.code,
    jumpType: '',
    logo: row.logo,
    params: `{"navTitle":"${row.meetingName}", pageId: "${row.pageId}", exhibitionCode: "${row.code}"}`,
    path: 'pages/index/index',
    r: 0,
    scenesType: 'USER_HOLD_EXHIBITION',
    width: 1280,
    miniPath: `subPackage/shops/pages/index-page/index?navTitle=${row.meetingName}&pageId=${row.pageId}&meetingCode=${row.code}`,
  }
  generateWxRef.value.handleOpen(row, qrCodeParams)
}


const handleUserManageFunc = (row) => {
  holdMeetUserManageRef.value.handleOpen(row)
}



// 编辑
const handleEdit = (row) => {
  refExDialog.value.handleOpen({ ...row })
}
// 条数
const handleSizeChange = debounce((pageSize) => {
  state.queryForm.pageSize = pageSize
  state.collapseItem = 0
  getData()
})


// 页码
const handleCurrentChange = debounce((pageNum) => {
  state.queryForm.pageNum = pageNum
  state.collapseItem = 0
  getData()
})
// 跳转门票配置
const handleJumpTicketsConfig = (row) => {
  router.push({
    path: '/exhibitionManage/ticketsConfig',
    query: { meetingCode: row.code },
  })
}

const handleLotteryManage = (row) => {
  router.push({
    path: '/marketing/lotteryManage',
    query: { meetingCode: row.code, type: 'EXHIBITION' },
  })
}

const handleIdentity = (row) => {
  router.push({
    path: '/exhibitionManage/identity',
    query: { meetingCode: row.code },
  })
}
// 跳转门票管理
const handleJumpTicketsManage = (row) => {
  router.push({
    path: '/exhibitionManage/ticketsManage',
    query: { meetingCode: row.code },
  })
}

// 跳转购票渠道
const handleChannelCode = (row) => {
  router.push({
    path: '/exhibitionManage/channelManage',
    query: { meetingCode: row.code },
  })
}

// 跳转邀请码渠道
const handleshareCodeChannel = (row) => {
  router.push({
    path: '/exhibitionManage/shareCodeChannel',
    query: { meetingCode: row.code, meetingType: row.aiApplicationType },
  })
}
// 跳转论坛关联
const handleForume = (row) => {
  router.push({
    path: '/exhibitionManage/relatedForum',
    query: { meetingCode: row.code },
  })
}

const handleVenue = (row) => {
  router.push({
    path: '/exhibitionManage/venueManage',
    query: { meetingCode: row.code },
  })
}
// 活动关联
const handleActiveAssociation = (row) => {
  // state.ticketEditRef.showEdit(row)
  router.push({
    path: '/exhibitionManage/association',
    query: { meetingCode: row.code, meetingType: row.aiApplicationType },
  })
}
// 跳转报名渠道
const handleChannel = (row) => {
  if (row.code) {
    router.push({
      path: '/exhibitionManage/channelDefend',
      query: {
        meetingCode: row.code,
        navTitle: row.meetingName,
        pageId: row.pageId,
        logo: row.logo,
        type: row.aiApplicationType,
      },
    })
  }
}

const handleShareChannel = (row) => {
  if (row.code) {
    router.push({
      path: '/exhibitionManage/shareChannel',
      query: {
        meetingCode: row.code,
        navTitle: row.meetingName,
        pageId: row.pageId,
        logo: row.logo,
        type: row.aiApplicationType,
      },
    })
  }
}
const handleChanneluser = (row) => {
  if (row.code) {
    router.push({
      path: '/exhibitionManage/channelUser',
      query: {
        meetingCode: row.code,
        navTitle: row.meetingName,
        pageId: row.pageId,
        logo: row.logo,
        type: row.aiApplicationType,
      },
    })
  }
}

const fullscreenLoading = ref(false)
const downLoadReport = async (row, type) => {
  console.log(row, type)
  let flag = await getPermissionCustomized(row)
  if (type == 'media') {
    console.log(flag)
    if (flag == 'HOST') {
      row.relationType = 'EXHIBITION'
      exportRegisterRef.value.handleOpen(
        row,
        `注册报表导出`,
        `MEETING_REGISTER_REPORT`
      )
    } else {
      proxy.$baseMessage(
        '您没有访问该功能的权限',
        'error',
        'vab-hey-message-error'
      )
    }

    return false
  }
  if (type == 'signUp') {
    if (flag == 'HOST') {
      row.relationType = 'EXHIBITION'
      exportRegisterRef.value.handleOpen(
        row,
        `签到报表导出`,
        `MEETING_SIGN_REPORT`
      )
    } else {
      proxy.$baseMessage(
        '您没有访问该功能的权限',
        'error',
        'vab-hey-message-error'
      )
    }

    return false
  }
  if (type == 'order') {
    if (flag == 'HOST') {
      fullscreenLoading.value = true
      exportExOrderReport(row.code).then((res) => {
        fullscreenLoading.value = false
        if (res.data.success) {
          window.open(res.data.fileUrl)
        }
      })
    } else {
      proxy.$baseMessage(
        '您没有访问该功能的权限',
        'error',
        'vab-hey-message-error'
      )
    }
  }

  if (type == 'ticket') {
    fullscreenLoading.value = true
    exportExTicketReport(row.code).then((res) => {
      // 以excel文件为例
      fullscreenLoading.value = false
      if (res.data.success) {
        window.open(res.data.fileUrl)
      }
    })
  }

  if (type == 'business') {
    if (flag == 'HOST') {
      fullscreenLoading.value = true
      exportMeetingInvitedReport(row.code).then((res) => {
        // 以excel文件为例
        fullscreenLoading.value = false
        if (res.data.success) {
          window.open(res.data.fileUrl)
        }
      })
    } else {
      proxy.$baseMessage(
        '您没有访问该功能的权限',
        'error',
        'vab-hey-message-error'
      )
    }
  }
}
// 跳转统计数据详情
const handleStatisticsDetail = (row) => {
  router.push({
    path: '/exhData',
    query: {
      meetingCode: row.code,
      meetingType: 'EXHIBITION',
      aiApplicationType: row.aiApplicationType,
    },
  })
}
const handleDaliyDetail = (row) => {
  router.push({
    path: '/daliyReport',
    query: {
      meetingCode: row.code,
      meetingType: row.aiApplicationType,
      meetingName: row.meetingName,
    },
  })
}

const handlePageConfig = (row) => {
  router.push({
    path: '/exhibitionManage/exHIbitionPageConfig',
    query: { meetingCode: row.code, linkEnterpriseCode: row.enterpriseCode },
  })
}

const handleGuest = (row) => {
  router.push({
    path: '/exhibitionManage/guestManage',
    query: { meetingCode: row.code },
  })
}

// 跳转注册管理
const handleManageRegister = (row) => {
  router.push({
    path: '/exhibitionManage/registerManage',
    query: { meetingCode: row.code },
  })
}

const handleBrand = (row) => {
  router.push({
    path: '/exhibitionManage/brand',
    query: {
      meetingCode: row.code,
      enterpriseCode: state.queryForm.enterpriseCode,
      meetingType: row.aiApplicationType,
    },
  })
}
const handleBrandProduct = (row) => {
  router.push({
    path: '/exhibitionManage/productList',
    query: { meetingCode: row.code },
    meetingType: row.aiApplicationType,
  })
}

const handleMatching = (row) => {
  router.push({
    path: '/exhibitionManage/matchingApply',
    query: { meetingCode: row.code },
  })
}

/**
 * @description: 添加协同账户
 * @param {*} row
 * @return {*}
 */
const handleSonAccount = async (row) => {
  let flag = await getPermissionCustomized(row)
  if (flag == 'HOST') {
    router.push({
      path: '/exhibitionManage/synergy',
      query: { meetingCode: row.code },
    })
  } else {
    proxy.$baseMessage(
      '您没有访问该功能的权限',
      'error',
      'vab-hey-message-error'
    )
  }
}

// 获取当前用户的企业信息
const fetchEnterPrise = async () => {
  let userInfoData = JSON.parse(localStorage.getItem('userInfo'))
  console.log(userInfoData)
  let params = {
    tid: userInfoData.tid,
    iamUid: userInfoData.uid,
  }
  const { data: userInfo } = await getUserEnterPriseAccount(params)
  state.enterPriseList = userInfo
  // state.queryForm.enterpriseCode = userInfo[0].enterpriseCode
}

const getPermissionCustomized = async (row) => {
  const { data } = await exhibitionQueryPermissions({
    meetingCode: row.code,
    meetingType: 'EXHIBITION',
  })
  console.log(data)
  return data
}

// 挂载
onActivated(async () => {
  await fetchEnterPrise()
  getData()
  let customInfo = localStorage.getItem('customInfo')
  console.log('customInfo', customInfo)
  state.tenantKey = JSON.parse(customInfo).tenantKey
})
</script>

<style lang="scss" scoped>
.exhibitionManageBox {
  background: #f5f5f5;
  height: 80vh;
}
.collapseTitle {
  font-size: 16px;
}
.el-descriptions__label {
  font-size: 14px;
}
.box-card {
  cursor: pointer;
  min-height: 253px;
  background: #fff;
  border-radius: 12px;
  padding: 0 12px 20px 12px;
  margin-bottom: 10px;
  // max-width: 500px;
  .title {
    height: 45px;
    line-height: 45px;
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    border-bottom: 1px solid #f5f5f5;
    display: flex;
    justify-content: space-between;
    .editIcon {
      font-size: 20px;
      line-height: 55px;
      color: #409eff;
    }
    .name {
      width: calc(100% - 30px);
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
  }
  .form {
    padding: 10px 0;
    border-bottom: 1px solid #f5f5f5;
    .form-item {
      display: flex;
      margin-top: 8px;
      .label {
        width: 30%;
        font-size: 12px;
        font-weight: 400;
        color: #909499;
      }
      .item {
        .iconbox {
          font-size: 10px;
          font-weight: 400;
        }
        .yishangjia {
          width: 60px;
          height: 17px;
          line-height: 17px;
          background: #e7faf0;
          border-radius: 2px;
          border: 1px solid #d0f5e0;
          color: #21cf6b;
          position: relative;
          padding-left: 13px;
          &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 6px;
            transform: translateY(-50%);
            width: 5px;
            height: 5px;
            background: #21cf6b;
            border-radius: 50%;
          }
        }
        .yixiajia {
          width: 60px;
          height: 17px;
          line-height: 17px;
          background: #feeded;
          border-radius: 2px;
          border: 1px solid #ffdbdd;
          color: #ff5153;
          position: relative;
          padding-left: 13px;
          &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 6px;
            transform: translateY(-50%);
            width: 5px;
            height: 5px;
            background: #ff5153;
            border-radius: 50%;
          }
        }
      }
    }
  }
  .controllBox {
    display: flex;
    justify-content: right;
    margin-top: 10px;
    .btn {
      min-width: 65px;
      height: 24px;
      border-radius: 4px;
      // border: 1px solid #e9e9e9;
      font-size: 12px;
      font-weight: 400;
      color: #909499;
      text-align: center;
      line-height: 24px;
      margin-left: 10px;
      &:hover {
        background: #409eff;
        color: #fff;
      }
    }
    .btn1 {
      width: 90px;
      height: 24px;
      border-radius: 4px;
      border: 1px solid #e9e9e9;
      font-size: 13px;
      font-weight: 400;
      color: #909499;
      text-align: center;
      line-height: 24px;
      margin-left: 10px;
      &:hover {
        background: #409eff;
        color: #fff;
      }
    }
    .dropdown {
      .dropdown-title {
        display: inline-block;
        position: relative;
        height: 60px;
        line-height: 60px;
        font-size: 20px;
        color: #fff;
        background-color: #000;
        padding: 0 24px;
        border-radius: 4px;
        cursor: pointer;
        // transition: all 1s ease-in-out;
      }

      .dropdown-content {
        // 定位显示局域
        position: absolute;
        visibility: hidden; // 隐藏
        opacity: 0; // 隐藏
        transition: all 0.6s ease-in-out;
        width: 100px;
        z-index: 99;
        .dropdown-menu {
          margin-top: 4px; // 与title制造距离
          padding: 5px 8px 15px; // 给下面多留一点距离，抵消视觉差
          color: white;
          background-color: rgba($color: #fff, $alpha: 1);
          border-radius: 4px;
          .menuItem {
            white-space: nowrap;
            font-size: 16px;
            color: white;
            cursor: pointer;
            border-radius: 4px;
            margin-top: 5px;
          }
        }
      }

      &:hover .dropdown-content {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
.card-header {
  display: flex;
  justify-content: space-between;
}

.el-dropdown-menu--small .el-dropdown-menu__item {
  margin-top: 5px;
  .btn {
    width: 55px;
    height: 24px;
    border-radius: 4px;
    border: 1px solid #e9e9e9;
    font-size: 10px;
    font-weight: 400;
    color: #909499;
    text-align: center;
    line-height: 24px;
  }
}
</style>
